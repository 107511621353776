import React, { useState } from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { EMAIL_INVALID, EMAIL_REQUIRED } from '../../constans/error-messages';
import { EMAIL_MAX } from '../../constans/validation';
import classes from './ResendLink.module.scss';
import InputWrapper from '../UI/InputWrapper/InputWrapper';
import Label from '../UI/Label/Label';
import { Input } from '../UI/Input/Input';
import { AUTH } from '../../constans/form-fields';
import Button from '../UI/Button/Button';

const validationSchema = Yup.object({
  email: Yup.string()
    .required(EMAIL_REQUIRED)
    .email(EMAIL_INVALID)
    .max(EMAIL_MAX),
});

const ResendLink = ({ submitHandler, email }) => {
  const [serverError, setServerError] = useState('');
  const initialValues = {
    email,
  };

  const formSubmitHandler = (values) => {
    submitHandler(values, setServerError);
  };

  return (
    <div className="center-vertical-page-wrapper blue-grad-bg flex flex-v-center">
      <div className={`${classes.container} auth-wrapper container-bordered`}>
        <div className="auth-title block-title">
          Resend verification link
          {serverError && <div className="titleError">{serverError}</div>}
        </div>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={formSubmitHandler}
        >
          {() => (
            <Form>
              <InputWrapper>
                <Label>Email</Label>
                <Input
                  hideError={false}
                  type="email"
                  name={AUTH.email.name}
                  placeholder={AUTH.email.placeHolder}
                />
              </InputWrapper>
              <Button
                classes=" btn btn_block btn-uppercased btn_common t-600"
                type="submit"
              >
                Resend link
              </Button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default ResendLink;
