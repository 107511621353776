import React from 'react';
import { navigate } from 'gatsby';

import http from '../../services/http.service';
import { API } from '../../constans/http';
import ResendLink from '../../components/ResendLink/ResendLink';
import useGetUserEmail from '../../hooks/useGetUserEmail';

const ResendEmailLink = () => {
  const [userEmail] = useGetUserEmail();
  const formSubmitHandler = (values, setServerError) => {
    http
      .post(API.EMAIL_VERIFY, { ...values })
      .then(() => {
        navigate('/email-notif', { state: { ...values } });
      })
      .catch(({ message }) => {
        setServerError(message);
      });
  };

  return <ResendLink email={userEmail} submitHandler={formSubmitHandler} />;
};

export default ResendEmailLink;
